/* site-wide style sheet */

body {
    font-family: sans-serif, Arial, Helvetica;
    margin: 0;
    padding: 0;
    }
    
    #privacyPolicy, #termsOfService {
      position:absolute;
      top: 0;
      left: 0;
      background: white;
      z-index:9999;
    }
    
    .projectzen-logo {
    width: 6em;
    margin: 8px;
    }
    
    .projectzen-logo-large {
    width: 7em;
    margin: 8px;
    }
    
    .small-button {
    padding: 0.44em 1.014em;
    border-radius: .32em;
    border-color: rgb(51,113,48);
    font-size: 1.02em;
    background-color: rgb(51,113,48);
    color: white;
    text-decoration: none;
    }
    
    .small-button:active {
    background-color: rgb(43,92,39);
    }
    
    .small-button:hover {
    color: white;
    text-decoration: none;
    background-color: rgb(33,95,30);
    }
    
    .position-top-right {
      float: right;
      margin: 8px;
    }
    
    .accountSettingsButton {
    color: white;
    background-color: rgb(51,113,48);
    float: right;
    }
    
    .accountSettingsButton:active {
    color: white;
    background-color: rgb(51,113,48);
    }
    
    .main-header {
    margin: 2.75em 0em 0.8em 0em;
    text-align: center;
    font-weight: bold;
    font-size: 2.6em;
    }
    
    .secondary-header {
      font-size: 2em;
    }
    
    .subheader {
    text-align: center;
    font-size: 1.4em;
    line-height: 150%;
    font-weight: normal;
    margin: 0em;
    }
    
    .big-button-container {
    width: 100%;
    background-color: transparent;
    color: white;
    font-size: 1em;
    border-style: none;
    }
    
    .position-centered {
      position: relative;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin: 2.2em auto 2.2em auto;
      display: block;
    }
    
    .big-button {
    font-size: 1.9em;
    border-radius: 0.32em;
    padding: 0.45em 1.368em;
    color: white;
    background-color: rgb(51,113,48);
    text-decoration: none;
    }
    
    .position-inline-with-margin {
      position: relative;
      display: inline;
      margin: 8px;
    }
    
    .big-button-container:active {
    background-color: transparent;
    }
    
    .big-button:active {
    background-color: rgb(43,92,39);
    }
    
    .big-button:hover {
    color: white;
    text-decoration: none;
    background-color: rgb(33,95,30);
    }
    
    .page-footer {
      bottom: 0;
      width:100%;
      text-align: center;
      margin: 0;
      padding: 0;
      margin-top: 15.5em;
      position: relative;
    }
    
    .page-footer > * {
      display: inline;
      color: rgb(195,195,195);
      font-size: 0.9em;
    }
    